import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchCompany } from "../../api"
import ProductUpdates from "../../components/ProductUpdates"
import Events from "../../components/Events"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import Card from "../../components/Card"
import SectionsDisplay from "../../components/Company/Metrics/SectionsDisplay"
import { YEARS } from "./Metrics"


const Home = () => {
  const navigate = useNavigate()
  const companyId = 'ed166146-82a5-461b-8bb7-9e0e0a254f58'
  const [company, setCompany] = useState<any | null>(null)

  useEffect(() => {
    fetchCompany(navigate, companyId).then((result) => setCompany(result))
  }, [])

  const METRICS_SECTION = [{
    divisionId: '93788fad-2a0a-4ec3-a9e2-7509d1f4e341',
    title: 'Key Business Metrics',
    onClick: () => { navigate(`/companies/${company.id}/metrics`)},
    rowConfigs: [
      {
        title: 'Total revenue (M)',
        metricType: 'total_revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'total_revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Total business customers',
        metricType: 'customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Small business & mid-market',
        metricType: 'small_business_mid_market',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Self-employed',
        metricType: 'self_employed',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Consumer',
        metricType: 'consumer',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  }]

  return (
    <CompanyPageContainer>
      <div className="flex flex-col pb-20">
        <Card className="p-10">
          <>
            {company && (
              <>
                <div className="font-bold text-3xl pb-6">Overview - {company.name}</div>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row gap-6">
                    <ProductUpdates company={company} />
                    <Events />
                  </div>
                  <SectionsDisplay company={company} years={YEARS} divisionConfigs={METRICS_SECTION}/>
                </div>
              </>
            )}
          </>
        </Card>
      </div>
    </CompanyPageContainer>
  )
}

export default Home