import SectionsDisplay, { SectionConfig } from "./SectionsDisplay"

const DIVISIONS: SectionConfig[] = [
  {
    divisionId: '93788fad-2a0a-4ec3-a9e2-7509d1f4e341',
    title: 'Key Business Metrics',
    headerSize: 'xtra-large',
    rowConfigs: [
      {
        title: 'Total revenue (M)',
        metricType: 'total_revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'total_revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Total business customers',
        metricType: 'customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Small business & mid-market',
        metricType: 'small_business_mid_market',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Self-employed',
        metricType: 'self_employed',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Consumer',
        metricType: 'consumer',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: 'fe0a474a-2070-439c-bdde-b43482dccd89',
    title: 'Global Business Solutions Group (GBSG)',
    headerSize: 'xtra-large',
    description: 'GBSB includes all products sold to businesses, including both online and desktop products. In August 2024, Intuit renamed the Small Business & Self-Employed segment to Global Business Solutions.',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex justify-start font-bold text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: 'fadab5a9-92ba-4c74-9c4a-41f15329e465',
    title: 'GBSG Online Ecosystem',
    headerSize: 'large',
    description: 'The Online Ecosystem includes all online business products, such as QuickBooks and Mailchimp.',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers',
        metricType: 'customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: 'c1708143-7c0a-40a3-9aa4-ec6739d695bb',
    title: 'QuickBooks Online Accounting',
    headerSize: 'medium',
    description: 'QuickBooks accounting software, not including additional services.',
    footer: "Products include: QuickBooks Simple Start, QuickBooks Essentials, QuickBooks Plus, QuickBooks Advanced, QuickBooks Live, QuickBooks Self-Employed, and QuickBooks Solopreneur",
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers (Global)',
        metricType: 'customer_global_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers (US)',
        metricType: 'customer_us_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers (Non-US)',
        metricType: 'customer_non_us_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Mid-market customers using QBO and Desktop SKUs in the US, Canada, UK, and Australia',
        metricType: 'midmarket_customers_qbo_us_can_uk_aus',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: 'dd5031a3-af27-4f87-ac5d-eeb01f5f7d14',
    title: 'Online Services',
    headerSize: 'medium',
    description: 'QuickBooks add-on services, including, payroll, contractor payments, Mailchimp, and more.',
    footer: "Products include: QuickBooks Online Payroll Core, QuickBooks Online Payroll Premium, QuickBooks Online Payroll Elite, QuickBooks Contractor Payments, QuickBooks Time Premium, QuickBooks Time Elite, QuickBooks Money, QuickBooks Payments, QuickBooks Capital, QuickBooks Bill Pay, QuickBooks Checking, and Mailchimp",
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Global Online payroll revenue (M)',
        metricType: 'online_payroll_revenue',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'online_payroll_revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Global payroll customers',
        metricType: 'global_customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'US workers paid annually',
        metricType: 'us_workers_paid_annually',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'QuickBooks payroll volume (US)',
        metricType: 'quickbooks_payroll_volume_us',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'QuickBooks time employees',
        metricType: 'quickbooks_time_employees',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: '7b607ea5-7592-410c-90d3-d5aa9a66056b',
    title: 'QuickBooks Online Advanced Ecosystem',
    headerSize: 'medium',
    description: 'QuickBooks Online Advanced Ecosystem includes revenue from add-ons like payroll and contractor payments in ARPC.',
    rowConfigs: [
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers',
        metricType: 'customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'customer_count_change_yoy',
        titleClassNames: 'flex justify-end text-end italic max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Retention',
        metricType: 'retention',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: 'd373d178-bb5d-4c0c-99b1-60051e5a0ed6',
    title: 'Mailchimp',
    headerSize: 'medium',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers',
        metricType: 'customer_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: '1b24bd44-48ec-4b17-bb82-18b3af55ae46',
    title: 'GBSG Desktop',
    headerSize: 'large',
    description: 'QuickBooks Desktop products.',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers (subs)',
        metricType: 'customer_subs_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Customers (units)',
        metricType: 'customer_units_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    title: 'Consumer',
    headerSize: 'xtra-large'
  },
  {
    divisionId: 'b0494cf7-c0c1-421d-9157-b2147e9767d3',
    title: 'Credit Karma',
    headerSize: 'medium',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPC',
        metricType: 'arpc',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Users',
        metricType: 'user_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'MAUs',
        metricType: 'maus',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  },
  {
    divisionId: '6531b9f8-0c68-46fb-ac88-9fa3ec52963d',
    title: 'TurboTax',
    headerSize: 'medium',
    rowConfigs: [
      {
        title: 'Revenue (M)',
        metricType: 'revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'revenue_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ARPR (per return)',
        metricType: 'arpr',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  }
]

type MetricsOverviewProps = {
  company: any
  years: string[]
}

const MetricsOverview = ({ company, years }: MetricsOverviewProps) => {
  return (
    <SectionsDisplay company={company} years={years} divisionConfigs={DIVISIONS} />
  )
}

export default MetricsOverview