import { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import ExtendedLogo from "./../assets/extended-logo.svg?react" 
import { Cog8ToothIcon } from "@heroicons/react/24/outline"

const Header = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-row border-b border-b-slate-200 justify-center shadow h-[75px]">
      <div className="flex flex-row justify-between items-center mx-20 w-full">
        <button className="cursor-pointer" onClick={() => { navigate('/') }}>
          <ExtendedLogo className="h-10 w-full"/>
        </button>
        <button onClick={() => navigate("/settings")}>
          <div className="self-center">
            <Cog8ToothIcon className="w-8 h-8 stroke-slate-900" />
          </div>
        </button>
      </div>
    </div>
  )
}

type PageContainerProps = {
  children?: ReactElement
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className="min-w-fit h-dvh">
      <Header />
      <div className="px-20 bg-faintOrange min-h-screen pt-10">
        {children}
      </div>
    </div>
  )
}

export default PageContainer