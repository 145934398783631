import { ReactElement } from "react"

const Card = ({ children, className }: { children?: ReactElement, className?: string }) => {
  return (
    <div className={`bg-white rounded-xl border ${className}`}>
      {children}
    </div>
  )
}

export default Card