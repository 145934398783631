import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { SignIn } from './pages/SignIn';
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Company from './pages/Company';
import Metrics from './pages/Company/Metrics';
import Documents from './pages/Company/Documents';

const store = configureStore({
  reducer: rootReducer
})

const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<PrivateRoute><Home></Home></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings></Settings></PrivateRoute>} />
        <Route path="/companies/:id" element={<PrivateRoute><Company></Company></PrivateRoute>} />
        <Route path="/companies/:id/metrics" element={<PrivateRoute><Metrics></Metrics></PrivateRoute>} />
        <Route path="/companies/:id/documents" element={<PrivateRoute><Documents></Documents></PrivateRoute>} />
        {/* <Route path="/create-report" element={<PrivateRoute><CreateReport></CreateReport></PrivateRoute>} /> */}
        {/* <Route path='/reports/:id' element={<PrivateRoute><ReportDetails></ReportDetails></PrivateRoute>} /> */}
        {/* <Route
          path='/reports/:id/news_source/:news_source_id'
          element={<PrivateRoute><ReportNewsSourceDetails></ReportNewsSourceDetails></PrivateRoute>}
        /> */}
        <Route path="/signin" element={<SignIn />} />
      </>
    )
  )

function App() {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router}/>
      </Provider>
    </>
  )
}

export default App
