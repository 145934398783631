import Header from "./Header"

const EVENTS = [
  {
    name: 'End of Q1',
    date: 'October 31, 2024'
  }
]

const Events = () => {
  return (
    <div className="border p-6 rounded min-w-[350px]">
      <Header className="pb-3">Upcoming Events</Header>
      {EVENTS.map((event: any) => {
        return (
          <div className="flex flex-row justify-between">
            <Header size="small">{event.name}</Header>
            <div className="text-sm">{event.date}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Events