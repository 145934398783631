import { useEffect, useState } from "react"
import { fetchCompany } from "../../api"
import { useNavigate, useParams } from "react-router-dom"
import isNil from "lodash/isNil"
import { Tabs } from "../../components/Tabs"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import MetricsOverview from "../../components/Company/Metrics/Overview"
import FinancialSummary from "../../components/Company/Metrics/FinancialSummary"
import Card from "../../components/Card"

export const YEARS = ['2024', '2023', '2022', '2021']

const Metrics = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [company, setCompany] = useState<any | null>(null)

  useEffect(() => {
    if (isNil(id)) {
      return
    } 
    
    fetchCompany(navigate, id).then((result) => setCompany(result))
  }, [])

  const tabs = [{
    title: 'Metrics Overview',
    tabContent: (<MetricsOverview company={company} years={YEARS} />)
  },{
    title: 'Financial Summary',
    tabContent: <FinancialSummary company={company} years={YEARS}></FinancialSummary>
  }
  ]

  return (
    <CompanyPageContainer>
      <Card className="p-10 w-full">
        <>
          <div className="font-bold text-3xl pb-6">Metrics</div>
          {company && <Tabs tabs={tabs} />}
        </>
      </Card>
    </CompanyPageContainer>
  )
}

export default Metrics