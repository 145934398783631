
const FormattedText = ({ text }: { text?: string}) => {
  if (!text) {
    return <></>
  }

  const splitSummary = text.split('\n')

  return (
    <div>
      {splitSummary.map((section, index) => {
        let sanitizedSection = section.replaceAll("#", "")
        const matchedBoldRegex = sanitizedSection.matchAll(/\*\*([^\*]*)\*\*/g)
        const bulletPoint = section.match(/^[\*-][^\*]/)
        sanitizedSection = sanitizedSection.replace(/^[\*-][^\*]/, "")

        const boldSections = new Set(Array.from(matchedBoldRegex).map((matchedData: any) => {
          return matchedData[1]
        }))
        const splitSection = sanitizedSection.split("**")

        const boldSection = () => {
          return (
            splitSection.map((snippet: any) => {
              if (boldSections.has(snippet)) {
                return <b className="font-bold">{snippet}</b>
              }

              return snippet.toString()
            }))
          }

        return (
          <div key={index}>
            {`${bulletPoint ? '•' : '' } `}
            {boldSection()}
            {index !== splitSummary.length - 1 && (<><br /></>)}
          </div>
        )
      })}
    </div>
  )
}

export default FormattedText