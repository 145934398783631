
import { ReactElement } from "react"
import { TableHeader } from "./TableHeader"
import { TableData } from "./TableData"

type ColumnConfig = {
  header: string
  key?: string
  classNames?: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
}

type TableProps = {
  columnConfigs: Array<ColumnConfig>
  rows: Array<any>
  onRowClick?: (row: any) => void
  tableClassNames?: string
  clickDisabled?: (row: any) => boolean
}

export const Table = ({ columnConfigs, rows, onRowClick, tableClassNames, clickDisabled = () =>false }: TableProps) => {
  return (
    <div>
      <div className={`overflow-x-auto border-t border-r border-l ${tableClassNames}`} >
        <table className="w-full bg-white">
          <thead className="bg-faintPurple">
            <tr>
              {columnConfigs.map((columnConfig, index) => <TableHeader key={index} header={columnConfig.header} />)}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              const rowClassNames = !clickDisabled(row) && onRowClick ? "cursor-pointer hover:bg-faintFeather" : ""

              return (
                <tr
                  key={index}
                  className={`border-solid border-t border-b ${rowClassNames}`}
                  onClick={() => {
                    if (clickDisabled(row)) {
                      return
                    }

                    onRowClick && onRowClick(row)
                  }}
                >
                  {columnConfigs.map((columnConfig, colIndex) => (
                    <TableData
                      key={`${index}-${colIndex}`}
                      element={row}
                      classNames={columnConfig.classNames}
                      keyString={columnConfig.key}
                      customRender={columnConfig.customRender}
                      minWidthPx={columnConfig.minWidthPx}
                    />)
                  )}
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}