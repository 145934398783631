
import { ReactElement } from "react"

export type RowConfig = {
  title?: string
  key?: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
  titleClassNames?: string
  metricClassNames?: string
  empty?: boolean
}

type TableProps = {
  rowConfigs: Array<RowConfig>
  data: Array<Array<any>>
  columnHeaders: Array<String>
  onRowClick?: (row: any) => void
  tableClassNames?: string
  clickDisabled?: (row: any) => boolean
}

type TableDataProps = {
  element: any
  classNames?: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
  empty?: boolean
  keyString?: string
}

type TableHeaderProps = {
  header: string
}

const TableHeader = ({ header }: TableHeaderProps) => {
  return <th className="p-2 font-medium text-sm text-feather">{header}</th>
}

const TableData = ({ element, keyString, classNames, minWidthPx, empty, customRender }: TableDataProps) => {
  const width = minWidthPx ? minWidthPx: 'min-w-[150px]'

  return (
    <td className={`px-2 py-1 text-sm text-center ${width}`}>
      <div className={classNames}>
        {empty && <></>}
        {!empty && !customRender && keyString && (element[keyString] ? element[keyString] : '-')}
        {!empty && customRender && customRender(element)}
      </div>
    </td>
  )
}

export const ColumnTable = ({ rowConfigs, data, columnHeaders, tableClassNames }: TableProps) => {
  return (
    <div>
      <div className={`overflow-x-auto border-t border-r border-l ${tableClassNames}`} >
        <table className="table-fixed w-full bg-white">
          <thead className="bg-faintPurple">
            <tr>
              <th></th>
              { /* @ts-ignore */}
              {columnHeaders.map((columnHeader, index) => <TableHeader key={index} header={columnHeader} />)}
            </tr>
          </thead>
          <tbody>
            {rowConfigs.map((rowConfig, index) => {
              return (
                <tr
                  key={index}
                  className={`border-solid border-t border-b`}
                >
                  <td className={`px-2 py-1 text-sm text-center ${rowConfig.titleClassNames ? rowConfig.titleClassNames : ''}`}>{rowConfig.title}</td>
                  {columnHeaders.map((_header, colIndex) => 
                  {
                    console.log("data Indexed")
                    console.log(data[index])
                    return (
                      <TableData
                        key={`${index}-${colIndex}`}
                        element={data && data[index] && data[index][colIndex] || '-'}
                        classNames={rowConfig.metricClassNames}
                        customRender={rowConfig.customRender}
                        minWidthPx={rowConfig.minWidthPx}
                        empty={rowConfig.empty}
                      />
                    )
                })}
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}