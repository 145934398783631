import SectionsDisplay from "./SectionsDisplay"

const SECTIONS = [
  {
    divisionId: '90620650-957e-49e4-8b2d-7d9ded8adfc1',
    title: 'Financial summary',
    footer: 'The numbers present are in millions, expect per share data.',
    rowConfigs: [
      {
        title: 'Small Business & Self Employed',
        metricType: 'small_business_self_employed',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'small_business_self_employed_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'Consumer',
        metricType: 'consumer',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'consumer_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'Credit Karma',
        metricType: 'credit_karma',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'credit_karma_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'ProTax',
        metricType: 'protax',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '% change YoY',
        metricType: 'protax_change_yoy',
        titleClassNames: 'flex italic justify-end text-end max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'Total Revenue',
        metricType: 'total_revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'GAAP Operating Income (Loss)',
        metricType: 'gaap_operating_income',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Non-GAAP Operating Income',
        metricType: 'non_gaap_operating_income',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Non-GAAP Operating Margin %',
        metricType: 'non_gaap_operating_margin',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'Interest Expense',
        metricType: 'interest_expense',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Interest and Other Income (Loss)',
        metricType: 'interest_other_income',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'GAAP Share-Based Compensation Expense',
        metricType: 'gaap_share_based_compensation_expense',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'GAAP Share-Based Compensation Expense per Share',
        metricType: 'gaap_share_based_compensation_expense_per_share',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'GAAP Diluted EPS',
        metricType: 'gaap_diluted_eps',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Non-GAAP Diluted EPS',
        metricType: 'non_gaap_diluted_eps',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'GAAP Diluted EPS (YTD)',
        metricType: 'gaap_diluted_eps_ytd',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Non-GAAP Diluted EPS (YTD)',
        metricType: 'non_gaap_diluted_eps_ytd',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'Basic Share Count',
        metricType: 'basic_share_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Diluted Share Count',
        metricType: 'diluted_share_count',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: '',
        metricType: '',
        titleClassNames: '',
        metricClassNames: 'h-5',
        empty: true,
      },
      {
        title: 'GAAP Tax Rate',
        metricType: 'gaap_tax_rate',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Non-GAAP Tax Rate',
        metricType: 'non_gaap_tax_rate',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
    ]
  },
  {
    divisionId: '5dadc228-c480-46d6-aaaa-9707033d371a',
    title: 'Segment revenue',
    footer: 'The numbers are in millions',
    rowConfigs: [
      {
        title: 'QuickBooks Online Accounting',
        metricType: 'quickbooks_online_accounting',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Online Services',
        metricType: 'online_services',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Total Online Ecosystem',
        metricType: 'total_online_ecosystem',
        titleClassNames: 'flex justify-end font-bold text-end max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'QuickBooks Desktop Accounting',
        metricType: 'quickbooks_desktop_accounting',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Desktop Services and Supplies',
        metricType: 'desktop_services_supplies',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Total Desktop Ecosystem',
        metricType: 'total_desktop_ecosystem',
        titleClassNames: 'flex justify-end font-bold text-end max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Small Business & Self-Employed',
        metricType: 'small_business_self_employed',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Consumer',
        metricType: 'consumer',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Credit Karma',
        metricType: 'credit_karma',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'ProTax',
        metricType: 'protax',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Total Segment Revenue',
        metricType: 'total_segment_revenue',
        titleClassNames: 'flex font-bold justify-start text-start max-w-[300px]',
        metricClassNames: 'font-bold',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
    ]
  },
  {
    divisionId: '14d9aa98-9d40-4024-b376-a4272e990273',
    title: 'Platform revenue',
    footer: 'The numbers are in millions',
    rowConfigs: [
      {
        title: 'Small Business & Self-Employed Group Online Ecosystem',
        metricType: 'small_business_self_employed_online_ecosystem',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'TurboTax Online',
        metricType: 'turbotax_online',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Credit Karma',
        metricType: 'credit_karma',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      },
      {
        title: 'Combined Platform Revenue',
        metricType: 'combined_platform_revenue',
        titleClassNames: 'flex justify-start text-start max-w-[300px]',
        metricClassNames: '',
        customRender: (row: any) => {
          const classNames = row.derived ? 'text-brightPurple' : ''

          return (
            <div className={classNames}>{row.value}</div>
          )
        }
      }
    ]
  }
]

type FinancialSummaryProps = {
  company: any
  years: string[]
}

const FinancialSummary = ({ company }: FinancialSummaryProps) => {
  return (
    <SectionsDisplay company={company} years={['2024', '2023', '2022']} divisionConfigs={SECTIONS}/>
  )
}

export default FinancialSummary