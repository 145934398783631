import PageContainer from "../components/PageContainer"
import Header from "../components/Header"
import Button from "../components/Button"
import Cookies from "js-cookie"
import { RIVETER_COOKIE } from "../api"
import { useNavigate } from "react-router-dom"

const Settings = () => {
  const navigate = useNavigate()
  const signOut = () => {
    Cookies.remove(RIVETER_COOKIE)

    navigate('/signin')
  }

  return (
    <PageContainer>
      <div className="flex flex-col pt-10 gap-6 ">
        <Header size="2xtra-large">Account Settings</Header>
        <div>
          <Button onClick={signOut}>Sign out</Button>
        </div>
      </div>
    </PageContainer>
  )
}

export default Settings