import Header from "./Header"

type Item = {
  columnOne: string
  columnTwo: string
}


type BoxListProps = {
  title: string
  titleSize?: 'medium' | 'large' | 'xtra-large'
  list: Item[]
}

const BoxList = ({ title, titleSize, list }: BoxListProps) => {
  return (
    <div>
      <Header size={titleSize} className="pb-3">{title}</Header>
      <div className="border p-6 rounded">
        {list.map((item) => {
          return (
            <div className="flex flex-row justify-between">
              <Header size="small">{item.columnOne}</Header>
              <div className="text-sm text-end">{item.columnTwo}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BoxList