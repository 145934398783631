type PillProps = {
  text: string
  color: string
  textColor: string
  border?: string
}

const Pill = ({ text, color, textColor, border }: PillProps) => {
  return (
    <div className={`shadow-inner ${color} ${textColor} ${border} rounded-full px-4 w-fit`}>
      {text}
    </div>
  )
}

export default Pill