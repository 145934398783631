import isNil from "lodash/isNil"
import { ColumnTable, RowConfig } from "../../ColumnTable"
import Header from "../../Header"

export type SectionConfig = {
  divisionId: string
  title: string
  headerSize?: 'medium' | 'large' | 'xtra-large'
  rowConfigs: (RowConfig & { metricType: string })[]
  description?: string
  footer?: string
  onClick?: () => void
} | {
  title: string
  headerSize?: 'medium' | 'large' | 'xtra-large'
}

type SectionsDisplayProps = {
  company: any
  years: string[]
  divisionConfigs: SectionConfig[]
}

const transformYearToHeader = (year: string) => {
  return `FY'${year.slice(2)}`
}

const SectionsDisplay = ({company, years, divisionConfigs }: SectionsDisplayProps) => {
  return (
    <div className="flex flex-col gap-6 pt-6" >
      {divisionConfigs.map((divisionConfig: any) => {
        if (isNil(divisionConfig.rowConfigs)) {
          return (
            <Header onClick={divisionConfig.onClick} className="pb-2" size={divisionConfig.headerSize || 'large'}>{divisionConfig.title}</Header>
          )
        }

        const columnHeaders = years.map((year) => transformYearToHeader(year))
        const division = company.divisions.filter((division: any) => division.id === divisionConfig.divisionId)[0]

        const data = divisionConfig.rowConfigs.map((rowConfig: any) => {
          const relevantMetrics = division.metrics.filter((metric: any) =>
            metric.type === rowConfig.metricType
          )

          return years.map((year) => {
            const metric = relevantMetrics.find((metric: any) => metric.year === year)

            return metric 
          })
        })

        return (
          <div>
            <Header onClick={divisionConfig.onClick} className="pb-2" size={divisionConfig.headerSize || 'large'}>{divisionConfig.title}</Header>
            <div className="pb-2 text-sm">{divisionConfig.description}</div>
            <ColumnTable
              rowConfigs={divisionConfig.rowConfigs}
              data={data}
              columnHeaders={columnHeaders}
              clickDisabled={(row) => row.status !== 'completed'}
              tableClassNames="rounded-md"
            />
            <div className="pt-3 text-xs">{divisionConfig.footer}</div>
          </div>
        )
      })}
    </div>
  )
}

export default SectionsDisplay