import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";

const Sidebar = ({ classNames }: { classNames?: string}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { id } = useParams();
  console.log(location.pathname.slice(location.pathname.lastIndexOf("/")))

  const pages = [
    {
      name: "Overview",
      url: ``

    },
    {
      name: "Metrics",
      url: `/metrics`
    },
    {
      name: "Documents & Artifacts",
      url: `/documents`
    }
  ]

  let activeTab = pages.map(page => page.url).indexOf(location.pathname.slice(location.pathname.lastIndexOf("/")))
  activeTab = activeTab != -1 ? activeTab : 0
  
  return (
    <div className={`min-w-[215px] ${classNames}`}>
      <>
        <Header size={"large"} className="pl-1 pb-3">Intuit</Header>
        {
          pages.map((page: any, index: number) => {
            const innerTabStyle = activeTab === index
              ? "text-ruby font-bold border-b-ruby bg-gradient-to-t from-lightRuby to-faintOrange"
              : "border-b-slate-300"

            return (
              <div
                className={`border-b ${innerTabStyle} pt-2 px-1 cursor-pointer`}
                onClick={() => {
                  navigate(`/companies/${id}${page.url}`)
                }}
              >
                {page.name}
              </div>
            )
          })
        }
      </>
    </div>
  )
}

export default Sidebar