import { useNavigate } from "react-router-dom"
import PageContainer from "../components/PageContainer"
import Card from "../components/Card"
import { Table } from "../components/Table"
import Pill from "../components/Pill"
import Header from "../components/Header"
import BoxList from "../components/BoxList"
import ExternalLink from "../components/ExternalLink"

const topCompetitors = [{
  name: "Intuit",
  stage: "Public",
  updates: true
},
{
  name: "ADP",
  stage: "Public",
  updates: false
},
{
  name: "Paychex",
  stage: "Public",
  updates: false
},
{
  name: "Paylocity",
  stage: "Public",
  updates: false
},
{
  name: "Rippling",
  stage: "Series F",
  updates: false
},
{
  name: "Deel",
  stage: "Series D",
  updates: false
}]

const newEntrants = [{
  name: 'Central',
  stage: 'Seed'
},
{
  name: 'Every',
  stage: 'Series A',
  updates: true
}]

const secondaryCompetitors = [{
  name: 'Square',
  stage: 'Public'
},
{
  name: 'Bill',
  stage: 'Public'
},
{
  name: 'Toast',
  stage: 'Public'
},
{
  name: 'TriNet',
  stage: 'Public'
},
{
  name: 'Justworks',
  stage: 'Series E'
}]

const columnConfigs = [{
  header: 'Company',
  key: 'name',
  classNames: ''
},
{
  header: 'Stage',
  key: 'stage',
  classNames: ''
},
{
  header: 'Updates',
  classNames: '',
  customRender: (row: any) => {
    if (!row.updates) {
      return (<></>)
    }

    return (
      <div className="flex justify-center items-center">
        <Pill text="New" textColor="text-ruby" color="bg-lightRuby" border="border border-ruby"/>
      </div>
    )
  }
}]

const investorRelations = [{
  columnOne: "ADP Q1 FY25 Earnings Call",
  columnTwo: "Oct 30, 2024"
},
{
  columnOne: "Intuit End of Q1",
  columnTwo: "Oct 31, 2024"
}]

const recentInvestorUpdates  = [
{
  columnOne: "Intuit Investor Day",
  columnTwo: "Sept 26, 2024"
},
{
  columnOne: "Intuit Enterprise Suite Press Release",
  columnTwo: "Sept 17, 2024"
},{
  columnOne: "ADP FY24 Earnings Call",
  columnTwo: "Aug 2024"
},
{
  columnOne: "ADP FY24 Earnings Call",
  columnTwo: "July 31, 2024"
}]

const news = [{
  title: 'Economy Private payrolls show better-than-expected growth of 143,000 in September, ADP says',
  url: 'https://www.cnbc.com/2024/10/02/private-payrolls-show-better-than-expected-growth-of-143000-in-september-adp-says.html',
  company: 'ADP', 
  date: 'Oct 2, 2024'
},
{
  title: 'The Revenue Playbook: Rippling’s Top 3 Growth Tactics at Scale with Rippling CRO Matt Plank',
  url: 'https://www.youtube.com/watch?v=h3eYtzBpjRw',
  company: 'Rippling', 
  date: 'Sept 27, 2024'
},
{
  title: 'Why Francis deSouza believes RTO is a board-level debate',
  url: 'https://www.hr-brew.com/stories/2024/09/25/francis-desouza-rto-board-level-debate',
  company: 'Deel', 
  date: 'Sept 25, 2024'
},{
  title: 'Why Startups like Rippling are Choosing New Products Over IPOs',
  url: 'https://www.theinformation.com/articles/why-startups-like-rippling-are-choosing-new-products-over-ipos',
  company: 'Rippling', 
  date: 'Sept 23, 2024'
},
{
  title: 'How Intuit plans to use agentic AI to automate complex business tasks',
  url: 'https://venturebeat.com/ai/how-intuit-plans-to-use-agentic-ai-to-automate-complex-business-tasks/',
  company: 'Intuit', 
  date: 'Sept 15, 2024'
}]

const newsConfig = [{
  header: 'Headline',
  customRender: (row: any) => {
    return (
      <>
        <ExternalLink classNames="line-clamp-2" text={row.title} url={row.url}/>
      </>
    )
  }
},
{
  header: 'Company',
  key: 'company'
},
{
  header: 'Date',
  key: 'date',
}]

const upcomingWebinars = [{
  title: 'No EOM Surprises: How to build an enforceable expense policy',
  company: 'Rippling',
  type: 'Webinar',
  date: 'Oct 10, 2024'
},
{
  title: '6 Ways To Simplify Your Global Contractor Management and Payments',
  company: 'Rippling',
  type: 'Webinar',
  date: 'Oct 15, 2024'
},
{
  title: 'Money2020',
  company: '',
  type: 'Conference',
  date: 'Oct 27, 2024'
}]

const upcomingWebinarConfig = [{
  header: 'Title',
  key: 'title',
  classNames: 'line-clamp-2'
},
{
  header: 'Company',
  key: 'company'
},
{
  header: 'Date',
  key: 'date'
}]


const Home = () => {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <Card className="grid grid-flow-rows grid-cols-2 p-10 gap-6">
        <>
          <div className="flex flex-col col-span-1">
            <div>
              <Header className="pb-3" size={'xtra-large'}>Competitors</Header>
              <Table
                rows={topCompetitors}
                columnConfigs={columnConfigs}
                onRowClick={(_row: any) => {
                  navigate('/companies/ed166146-82a5-461b-8bb7-9e0e0a254f58')
                }}
                clickDisabled={(row: any) => row.name != 'Intuit'}
              />
            </div>
            <div>
              <Header className="pb-3 pt-6" size={'xtra-large'}>New entrants</Header>
              <Table rows={newEntrants} columnConfigs={columnConfigs}/>
            </div>
            <div>
              <Header className="pb-3 pt-6" size={'xtra-large'}>Secondary competitors</Header>
              <Table rows={secondaryCompetitors} columnConfigs={columnConfigs}/>
            </div>
          </div>
          <div className="flex flex-col col-span-1 gap-6">
            <BoxList title="Upcoming investor relations dates" titleSize='xtra-large' list={investorRelations}/>
            <BoxList title="Recent investor relations updates & analysis" titleSize='xtra-large' list={recentInvestorUpdates}/>
            <div>
              <Header className="pb-3" size={'xtra-large'}>News</Header>
              <Table rows={news} columnConfigs={newsConfig}/>
            </div>
            <div>
              <Header className="pb-3" size={'xtra-large'}>Upcoming webinars and conferences</Header>
              <Table rows={upcomingWebinars} columnConfigs={upcomingWebinarConfig}/>
            </div>
          </div>
        </>
      </Card>
    </PageContainer>
  )
}

export default Home