import { ReactElement } from "react"
import PageContainer from "../PageContainer"
import Sidebar from "../Sidebar"

const CompanyPageContainer = ({ children }: {children: ReactElement}) => {
  return (
    <PageContainer>
      <div className="flex flex-row">
        <Sidebar classNames="pr-5"/>
        {children}
      </div>
    </PageContainer>
  )
}

export default CompanyPageContainer