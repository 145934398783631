import { useNavigate } from "react-router-dom"
import Header from "./Header"
import FormattedText from "./FormattedText"

const PRODUCT_UPDATES = [
  {
    name: 'Investor Day 2024',
    date: 'September 26, 2024',
    category: 'presentation',
    summary: `* Intuit's expansion into mid-market is fueling higher cross-platform adoption and larger deal sizes
* Intuit's new Enterprise Suite has initial ARPCs of $20k or higher
* QBO Advanced has 12pt higher payroll adoption than QBO Core
* Number of US workers paid stayed flat at 18M, the same as last year
* Intuit Assist, their GenAI product, is driving core metrics, including a lift in QBO onboarding completion`
  },
  {
    name: 'Intuit Introduces Intuit Enterprise Suite to Help Businesses Grow, Streamline Operations, and Scale',
    date: 'September 17, 2024',
    category: 'press_release',
    summary: "Intuit announced its Enterprise Suite for mid-market companies, citing a study on 10-99 EE businesses and using a 25-person construction company for the demo, without specifying a target employee size."
  },
  {
    name: 'FY24 Q4 10-K Document',
    date: 'September 4, 2024',
    category: '10k_filing',
    summary: `* Intuit ended the fiscal year with $16.3B in revenue, up 13%
* The "Small Business & Self-Employed" segment was renamed Global Business Solutions, contributing 59% of revenue ($9.5B), a 19% increase from FY23`
  }
]

const formatCategory = (category: any) => {
  switch(category) {
    case 'presentation': {
      return 'Presentation'
    }
    case 'press_release': {
      return 'Press Release'
    }
    case '10k_filing': {
      return '10k Filing'
    }
  }
}

const ProductUpdates = ({ company }: { company: any }) => {
  const navigate = useNavigate()

  console.log(company)
  return (
    <div className="border p-6 rounded">
      <Header className="pb-3" onClick={() => navigate(`/companies/${company.id}/documents`)}>Latest relevant updates</Header>
      <div className="flex flex-col gap-3">
        {PRODUCT_UPDATES.map((productUpdate: any) => {
          return (
            <div>
              <Header className="flex" size="medium">
                <>
                  {productUpdate.name}
                </>
              </Header>
              <div className="text-sm py-1 text-feather">{formatCategory(productUpdate.category)}, {productUpdate.date}</div>
              <div className="text-sm"><FormattedText text={productUpdate.summary}></FormattedText></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductUpdates