import { useState } from "react"
import Card from "../../components/Card"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import FormattedText from "../../components/FormattedText"
import Header from "../../components/Header"
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"

const DOCUMENTS = [{
  title: 'Investor Day 2024',
  date: 'September 26, 2024',
  type: 'presentation',
  summary: `* Intuit's expansion into mid-market is fueling higher cross-platform adoption and larger deal sizes
* Intuit's new Enterprise Suite has initial ARPCs of $20k or higher
* QBO Advanced has 12pt higher payroll adoption than QBO Core
* Number of US workers paid stayed flat at 18M, the same as last year
* Intuit Assist, their GenAI product, is driving core metrics, including a lift in QBO onboarding completion\n\n
**Intuit's business growth appears to be bolstered by two main factors**: 
1. Expansion up market, with broader adoption across the platform and larger deal sizes
2. International expansion, especially in mid-market
\n1. **Mid-market expansion and broader platform adoption**
QBO Advanced and Intuit Enterprise Suite are driving growth in the mid-market, targeting 10-100 EE companies. These products have higher ARCPs and more cross-platform adoption than QBO Core customers.\n
- "ARPC expansion driven by platform adoption and mid-market expansion" (pg 63)
- "QBO Advanced ecosystem ARPC is 5x that of the rest of the base, with Payroll and Payments penetration exceeding that of QBO Core by 12 pts and 9 pts respectively." (pg 63)
- "Intuit Enterprise Suite (IES) has an estimated $20k initial ARPC (pg 63), compared to the QBO Advanced ARPC of $3,299 and overall Online Ecosystem ARCP of $859" (pg 116).
- "283K QBO Advanced customers, +28% YoY.\" (pg 84)
- "28% growth in QBO Advanced customers, bolstered by their 84% retention (+2 pts YoY)." (pg 62)
- "13% growth in new Mailchimp mid-market paid subscribers." (pg 63)
- "55% YoY increase in rate of 3+ platform offerings adoption by new QBO cohorts.\" (pg 63)
- Online payroll revenue grew to $1.1B, a 23% YoY increase. (pg 29)
- "57% growth in mid-market customers using our highest-end Payroll SKU." (pg 81)
\n2. **International expansion, especially in payroll adoption**
The number of US workers paid annually stayed flat at 18M (pg 24), suggesting the 23% YoY payroll revenue growth is driven by mid-market and international expansion.\n
- "28% growth in QBO Advanced customers to 283K, including 69% international growth" (pg 77)
- "13% international Online revenue growth." (pg 83)
- ">50% Mailchimp paying customers are outside the US." (pg 83)
- "16% YoY increase in Payroll subscribers in Canada and the UK." (pg 81)
\n**AI via Intuit Assist is driving core metrics**
* "45% increase in the speed at which QBO customers get paid when they use Intuit Assist invoice reminders, expected to drive increased payments usage." (pg 117)
* "9 pt lift in onboarding completion rates for QBO trialers using GenAI, expected to drive improved new customer conversion and retention." (pg 117)`,
  quotes: []
},
{
  title: 'Intuit Introduces Intuit Enterprise Suite to Help Businesses Grow, Streamline Operations, and Scale',
  date: 'September 17, 2024',
  type: 'press_release',
  summary: "Intuit announced its Enterprise Suite for mid-market companies, citing a study on 10-99 EE businesses and using a 25-person construction company for the demo, without specifying a target employee size.",
  quotes: [
    '"With the power of AI, Intuit Enterprise Suite enhances profitability end-to-end with a full set of core capabilities all in one place, including: access to robust financial management and accounting capabilities; integrated payroll, payments, and bill pay; automated marketing; access to third-party apps, and a team of experts. It meets the needs of existing QuickBooks customers looking for more powerful functionality and will also include industry-specific customizations for construction, non-profit, service and project-based businesses."',
    '"...growing businesses are juggling multiple applications to manage their operations - using 10 different software programs on average - leading to overspending on unused software and time wasted on manual and repetitive tasks due to the lack of an integrated system. Among businesses prioritizing growth, the top improvements they say are needed for their current apps to support that growth are better integration capabilities and more automation to streamline processes and reduce manual work."',
    '"Stay compliant, pay, and manage teams with confidence: Businesses have access to robust payroll and HRIS features beyond just paying employees. Intuit Enterprise Suite includes automated new-hire onboarding processes and access to healthcare, retirement, and workers\' comp benefit offerings. It simplifies the overall payroll process with integrated time-tracking features and helps businesses stay compliant by improving accuracy with automated payroll tax calculations, deductions, and filings, and will soon detect anomalies with AI to catch errors."'
  ]
},
{
  title: 'FY24 10-K',
  date: 'September 4, 2024',
  type: '10k_filing',
  summary: `* Intuit ended the fiscal year with $16.3B in revenue, up 13%
* The "Small Business & Self-Employed" segment was renamed Global Business Solutions, contributing 59% of revenue ($9.5B), a 19% increase from FY23`,
  quotes: [
    '"On August 1, 2024, we renamed our Small Business & Self-Employed segment as the Global Business Solutions segment. This new name better aligns with the global reach of the Mailchimp and QuickBooks platform, our focus on serving both small and mid-market businesses, and our vision to become the end-to-end platform that customers use to grow and run their business." (pg 34)',
    '- "**Disrupt the small business mid-market**: We aim to disrupt the mid-market with a tailor-made, integrated ecosystem, which includes our QuickBooks offerings, as well as our workforce solutions and money offerings. These solutions are designed to address the needs of mid-market business customers with 10 to 100 employees. Mailchimp’s marketing offerings enable mid-market businesses to digitally promote their business across email, social media, landing pages, ads, websites, and more, all from one place. These offerings enable us to increase retention of these larger customers and accelerate mid-market demand." (pg 5)',
    '- "Online Ecosystem revenue increased $1.1 billion, or 20%, in fiscal 2024 compared with fiscal 2023. Online Services revenue increased $603 million, or 21%, in fiscal 2024 compared with fiscal 2023 due to increases in revenue from our payroll offerings of $211 million, our payments offerings of $203 million, and Mailchimp of $125 million... Online payroll revenue increased due to customer growth, higher effective prices, and a shift in mix to higher-end offerings. " (pg 42)'
  ]
}
]

const Documents = () => {
  return (
    <CompanyPageContainer>
      <Card className="p-10 w-full">
        <>
          <div className="font-bold text-3xl pb-6 ">Documents & Artifacts</div>
          <div className="flex flex-col divide-y">
            {DOCUMENTS.map((document) => {
              const [expanded, setExpanded] = useState(false)

              return (
                <div className="pt-6 pb-3">
                  <Header>
                    <div className="flex gap-2 items-center">
                      {document.title}
                      <ArrowDownTrayIcon className="stroke-ruby size-5 cursor-pointer hover:stroke-ruby/80"/>
                    </div>
                  </Header>
                  <div className="text-feather text-sm">{document.date}</div>
                  <div className={expanded ? "" : "line-clamp-3"}>
                    <Header className="pt-3" size="small">Summary</Header>
                    <FormattedText text={document.summary} />
                    {document.quotes.length > 0 && <Header className="pt-3" size="small">Notable quotes</Header>}

                    {document.quotes.map((quote, index) => {
                      return (
                        <>
                          {index > 0 && <br />}
                          <FormattedText key={index} text={quote} />
                        </>
                      )
                    })}
                  </div>
                  {!expanded && (
                    <div className="flex items-center gap-1 cursor-pointer text-feather text-sm pt-2" onClick={() => setExpanded(true)}>
                      See more
                      <ChevronDownIcon className="text-feather size-4"/>
                    </div>
                  )}
                  {expanded && (
                    <div className="flex items-center gap-1 cursor-pointer text-feather text-sm pt-2" onClick={() => setExpanded(false)}>
                      See less
                      <ChevronUpIcon className="text-feather size-4"/>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </>
      </Card>
    </CompanyPageContainer>
  )
}

export default Documents